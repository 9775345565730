import React from "react";
import { connect } from 'react-redux'
import { Modal, Button } from 'react-bootstrap';

import { onReset } from '../redux/actions'

const PlayAgain = (props) => {
    const { score, tileCount } = props;

    return <Modal
        show={score === tileCount}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        backdrop='static'
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter" className='mx-auto text-center'>
                Perfect Score - Well Done!
            </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
        <Button className='d-block mx-auto' onClick={props.onReset}>Play Again</Button>
        </Modal.Footer>
    </Modal>;
};

export default connect(
    // mapStateToProps
    state => ({
        score: state.gamePlay.score,
        tileCount: state.gamePlay.tileSet.tiles.length
    }),
    // mapDispatchToProps
    {onReset},
)(PlayAgain);
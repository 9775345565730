import { CHANGE_TILE_SETS, RESET_GAME, TILE_CLICK } from "./actionTypes";

/**
 * 
 * Actions are payloads of information that send data from your application to your store.
 * They are the only source of information for the store.
 * @see {@link https://redux.js.org/basics/actions}
 */

export const onTileClick = (evt) => ({
    type: TILE_CLICK,
    payload: {
        tileId: evt.target.src
    }
});

export const onTileSetChange = (tileSet) => ({
    type: CHANGE_TILE_SETS,
    payload: { tileSet }
});

export const onReset = () => ({
    type: RESET_GAME,
    payload: null
});
/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 * Remember that actions only describe what happened, but don't describe how the application's state changes.
 * @see {@link https://redux.js.org/basics/reducers}
 * 
 * Here we are combinging two reducers into a single root reducer,
 * and also creating a rootState, which will be the initial state passed to the store.
 */


import { combineReducers } from "redux";   // @see {@link https://redux.js.org/api/combinereducers/}
import gamePlay, {initialState as gpState} from "./gamePlay";
import tileSetManager, {initialState as tileSetState} from "./tileSetManager";

/**
 * Create correctly setup initial state for root reducer
 */
const initialState = {
    gamePlay: gpState,
    tileSetManager: tileSetState
};
initialState.gamePlay.tileSet = tileSetState.tileSets.find(it => it.key === tileSetState.selectedSet)

export const rootState = initialState;

/**
 * Merging our separate reducers into one root reducer
 */
export default combineReducers({
    gamePlay, 
    tileSetManager
});
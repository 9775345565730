import React from "react";
import { connect } from 'react-redux'

import Tile from '../Tile';

function GameBoard(props) {
    return <div
            className='gameboard mx-auto'
        >
            {
                props.shuffledTiles.map((tile, idx) =>
                    <div className='tile' key={idx}>
                        <Tile
                            src={tile}
                        />
                    </div>)
            }
        </div>
}


/**
 * The connect() function connects a React component to a Redux store.
 * It provides its connected component with the pieces of the data it needs from the store, 
 * and the functions it can use to dispatch actions to the store.
 * It does not modify the component class passed to it; 
 * instead, it returns a new, connected component class that wraps the component you passed in.
 * 
 * @see {@link https://react-redux.js.org/api/connect}
 */
export default connect(
    // mapStateToProps
    state => ({ shuffledTiles: state.gamePlay.shuffledTiles }),
)(GameBoard);

/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 * Remember that actions only describe what happened, but don't describe how the application's state changes.
 * @see {@link https://redux.js.org/basics/reducers}
 * 
 * This reducer handles just one action.
 */


 import { CHANGE_TILE_SETS } from "../actionTypes";

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_TILE_SETS: {
            const { tileSet } = action.payload;
            return {
                ...state,
                selectedSet: tileSet.key
            };
        }
        default:
            return {
                ...state
            };
    }
}

export const initialState = {
    selectedSet: 'amgdolls',
    tileSets: [
        {
            key: 'amgdolls',
            name: 'American Girl Dolls',
            tiles: [
                '/img/amgdolls/ashlyn.jpeg',
                '/img/amgdolls/blair.jpeg',
                '/img/amgdolls/joss.jpeg',

                '/img/amgdolls/julie.jpeg',
                '/img/amgdolls/kaya.jpeg',
                '/img/amgdolls/maryellen.jpeg',

                '/img/amgdolls/nanea.jpeg',
                '/img/amgdolls/rebecca.jpeg',
                '/img/amgdolls/willa.jpeg'
            ]
        },
        {
            key: 'dumptrucks',
            name: 'Dump Trucks',
            tiles: [
                '/img/dumptrucks/mackblack.jpg',
                '/img/dumptrucks/red-gray.jpg',
                '/img/dumptrucks/all-yellow.jpeg',

                '/img/dumptrucks/blackred.jpg',
                '/img/dumptrucks/cat-mine.jpeg',
                '/img/dumptrucks/allblue.jpg',

                '/img/dumptrucks/greenyellow.jpg',
                '/img/dumptrucks/peterbilt.jpeg',
                '/img/dumptrucks/blue-yellow.jpg'
            ]
        },
        {
            key: 'sailboats',
            name: 'Sailboats',
            tiles: [
                '/img/sailboats/55ba6922f1a78c3c877f81fb201cf329.jpg',
                '/img/sailboats/fssggyjk6k765.jpg',
                '/img/sailboats/ghtrkm6.jpg',

                '/img/sailboats/grtddgw3.jpg',
                '/img/sailboats/hdgre43.jpg',
                '/img/sailboats/hhjt434.jpg',

                '/img/sailboats/hrjhj5.jpg',
                '/img/sailboats/kjuy7uy5.jpg',
                '/img/sailboats/xy8932nsd.jpg'
            ]
        }
    ]
};
import React from "react";
import { connect } from 'react-redux'

import { onTileClick } from '../../redux/actions'

import './TileStyles.scss';


function Tile(props) {
  const styles = {
      width: '100%',
      height: '100%'
  };

  const {score, gamesPlayed} = props, 
    makeItJiggle = score === 0 && gamesPlayed > 0 ? 'jiggle ' : '';

  return (
    <img 
    alt=''
    src={props.src} 
    onClick={props.onTileClick} 
    className={makeItJiggle + 'img-thumbnail shadow-sm'} 
    style={styles}
    />
  );
}

/**
 * The connect() function connects a React component to a Redux store.
 * It provides its connected component with the pieces of the data it needs from the store, 
 * and the functions it can use to dispatch actions to the store.
 * It does not modify the component class passed to it; 
 * instead, it returns a new, connected component class that wraps the component you passed in.
 * 
 * @see {@link https://react-redux.js.org/api/connect}
 */
export default connect(
  // mapStateToProps
  state => ({ 
    score: state.gamePlay.score,
    gamesPlayed: state.gamePlay.gamesPlayed
  }),
  // mapDispatchToProps
  { onTileClick }
)(Tile);

import React from "react";
import { createRoot } from 'react-dom/client';

import { Provider } from "react-redux";
import store from "./redux/store";

import App from "./App";

/**
 * Wrapping our entire app in the react-redux Provider component
 * 
 * The <Provider /> makes the Redux store available to any nested components that have been wrapped in the connect() function.
 * Since any React component in a React Redux app can be connected,
 * most applications will render a <Provider> at the top level,
 * with the entire app’s component tree inside of it.
 * Normally, you can’t use a connected component unless it is nested inside of a <Provider>.
 * 
 * @see {@link https://react-redux.js.org/api/provider}
 */
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
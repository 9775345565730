/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 * Remember that actions only describe what happened, but don't describe how the application's state changes.
 * @see {@link https://redux.js.org/basics/reducers}
 * 
 * This reducer handles 2 actions.
 * Notice how, in every return statement, state is always copied over (replaced), not modified directly.
 */


import { CHANGE_TILE_SETS, RESET_GAME, TILE_CLICK } from "../actionTypes";


export default function (state = initialState, action) {

  switch (action.type) {
    case TILE_CLICK: {
      const { tileId } = action.payload;

      if (state.clickedTiles.includes(tileId)) {
        return {
          ...state,
          shuffledTiles: shuffleTiles(state.tileSet.tiles),
          score: 0,
          topScore: state.topScore,
          clickedTiles: [],
          gamesPlayed: state.gamesPlayed + 1
        };
      }

      const score = state.score + 1;
      const topScore = score > state.topScore ?
        score :
        state.topScore;

      return {
        ...state,
        shuffledTiles: shuffleTiles(state.tileSet.tiles),
        score,
        topScore,
        clickedTiles: [...state.clickedTiles, tileId]
      };
    }

    // re-using action type and action in other reducer
    // see: https://github.com/reduxjs/redux/issues/749#issuecomment-167000015
    case CHANGE_TILE_SETS: {
      const { tileSet } = action.payload;
      return {
        ...state,
        tileSet,
        shuffledTiles: shuffleTiles(tileSet.tiles),
        score: 0,
        topScore: 0,
        clickedTiles: [],
        gamesPlayed: 0
      };
    }

    case RESET_GAME: {
      return {
        ...state,
        shuffledTiles: shuffleTiles(state.tileSet.tiles),
        score: 0,
        clickedTiles: [],
        gamesPlayed: 0
      };
    }

    default: {
      return {
        ...state,
        shuffledTiles: shuffleTiles(state.tileSet.tiles),
      };
    }
  }
}

export const initialState = {
  tileSet: { tiles: [] },
  shuffledTiles: [],
  score: 0,
  topScore: 0,
  clickedTiles: [],
  gamesPlayed: 0
};


const randomSort = () => 0.5 - Math.random();
const shuffleTiles = (tiles) => [...tiles].sort(randomSort);
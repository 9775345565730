import React from "react";
import { connect } from 'react-redux'
import { Container, DropdownButton, Dropdown, Navbar } from 'react-bootstrap';

import { onTileSetChange } from '../redux/actions'


const Nav = (props) => {
    return <Navbar bg="primary" variant="light">
        <Container>
        <Navbar.Brand className="mr-auto">Memory Game</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
            <DropdownButton
                id="dropdown-basic-button"
                title={'Tile Set: ' + props.tileSet.name}
                size='lg'
                className="ml-auto"
            >
                {
                    props.tileSets.map(tileSet => <Dropdown.Item
                        onClick={() => props.onTileSetChange(tileSet)}
                        key={tileSet.key}
                    >{tileSet.name}</Dropdown.Item>)
                }
            </DropdownButton>
        </Navbar.Collapse>
        </Container>
    </Navbar>;
};

/**
 * The connect() function connects a React component to a Redux store.
 * It provides its connected component with the pieces of the data it needs from the store, 
 * and the functions it can use to dispatch actions to the store.
 * It does not modify the component class passed to it; 
 * instead, it returns a new, connected component class that wraps the component you passed in.
 * 
 * @see {@link https://react-redux.js.org/api/connect}
 */
export default connect(
    // mapStateToProps
    state => ({
        tileSet: state.gamePlay.tileSet,
        tileSets: state.tileSetManager.tileSets,
    }),
    // mapDispatchToProps
    { onTileSetChange }
)(Nav);
import React from "react";
import { connect } from 'react-redux'
import { Card, ProgressBar } from 'react-bootstrap';

const Score = (props) => {
  const { score, topScore, tileCount } = props;

  return <Card className="text-center" >
    <Card.Body>
      <Card.Title className="center-text">Top Score: {topScore}</Card.Title>
        <ProgressBar variant="success" now={(score / tileCount) * 100} label={score} />
    </Card.Body>
  </Card>;
}

/**
 * The connect() function connects a React component to a Redux store.
 * It provides its connected component with the pieces of the data it needs from the store, 
 * and the functions it can use to dispatch actions to the store.
 * It does not modify the component class passed to it; 
 * instead, it returns a new, connected component class that wraps the component you passed in.
 * 
 * @see {@link https://react-redux.js.org/api/connect}
 */
export default connect(
  // mapStateToProps
  state => ({
    score: state.gamePlay.score,
    topScore: state.gamePlay.topScore,
    tileCount: state.gamePlay.tileSet.tiles.length
  }),
  // mapDispatchToProps
  null,
)(Score);